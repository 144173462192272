import * as R from 'ramda'
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { faFile } from '@fortawesome/free-regular-svg-icons'
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  FormControl,
  Input,
  InputLabel,
  Link,
  ListItemText,
  MenuItem,
  Select,
  Step,
  StepButton,
  StepContent,
  Stepper,
  TextField,
  Typography
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import ChipInput from 'material-ui-chip-input'
import FsLightbox from 'fslightbox-react'

import { PictureAsPdf } from '@material-ui/icons'
import { DropzoneArea } from 'material-ui-dropzone'
import Swal from 'sweetalert2'

import { mapIndexed, nilOrEmpty, notNilOrEmpty } from '../lib/Helpers'
import Exhibitor from '../services/Exhibitor'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import ExhibitorsDropdown from './ExhibitorsDropdown'

const INITIAL_EXHIBITOR_STATE = {
  title: '',
  country: null,
  company_address: '',
  company_address_city: '',
  company_address_state: '',
  company_address_zipcode: '',
  company_phone: '',
  company_primary_contact: '',
  company_primary_contact_email: '',
  company_url: '',
  company_files: [],
  booth_hero_img: null,
  booth_hero_img_url: '',
  booth_hero_img_name: '',
  company_img: null,
  company_img_name: '',
  company_file_asset: null,
  company_zoom_url: '',
  company_info: '',
  company_video_url: '',
  company_intro_video: '',
  tawkto_chat_link: '',
  challengeType: '',
  submission_details_url: '',
  solution_id: '',
  sba_status: '',
  other_business_status: '',
  wbe: '',
  minority_owned: '',
  veteran_owned: '',

  bc__company_who_are_you_img: null,
  bc__company_who_are_you_img_name: null,
  bc__who_are_you: ''
}

const countries = [
  'Andorra',
  'United Arab Emirates',
  'Afghanistan',
  'Antigua and Barbuda',
  'Anguilla',
  'Albania',
  'Armenia',
  'Angola',
  'Antarctica',
  'Argentina',
  'American Samoa',
  'Austria',
  'Australia',
  'Aruba',
  'Alland Islands',
  'Azerbaijan',
  'Bosnia and Herzegovina',
  'Barbados',
  'Bangladesh',
  'Belgium',
  'Burkina Faso',
  'Bulgaria',
  'Bahrain',
  'Burundi',
  'Benin',
  'Saint Barthelemy',
  'Bermuda',
  'Brunei Darussalam',
  'Bolivia',
  'Brazil',
  'Bahamas',
  'Bhutan',
  'Bouvet Island',
  'Botswana',
  'Belarus',
  'Belize',
  'Canada',
  'Cocos (Keeling) Islands',
  'Congo, Democratic Republic of the',
  'Central African Republic',
  'Congo, Republic of the',
  'Switzerland',
  "Cote d'Ivoire",
  'Cook Islands',
  'Chile',
  'Cameroon',
  'China',
  'Colombia',
  'Costa Rica',
  'Cuba',
  'Cape Verde',
  'Curacao',
  'Christmas Island',
  'Cyprus',
  'Czech Republic',
  'Germany',
  'Djibouti',
  'Denmark',
  'Dominica',
  'Dominican Republic',
  'Algeria',
  'Ecuador',
  'Estonia',
  'Egypt',
  'Western Sahara',
  'Eritrea',
  'Spain',
  'Ethiopia',
  'Finland',
  'Fiji',
  'Falkland Islands (Malvinas)',
  'Micronesia, Federated States of',
  'Faroe Islands',
  'France',
  'Gabon',
  'United Kingdom',
  'Grenada',
  'Georgia',
  'French Guiana',
  'Guernsey',
  'Ghana',
  'Gibraltar',
  'Greenland',
  'Gambia',
  'Guinea',
  'Guadeloupe',
  'Equatorial Guinea',
  'Greece',
  'South Georgia and the South Sandwich Islands',
  'Guatemala',
  'Guam',
  'Guinea-Bissau',
  'Guyana',
  'Hong Kong',
  'Heard Island and McDonald Islands',
  'Honduras',
  'Croatia',
  'Haiti',
  'Hungary',
  'Indonesia',
  'Ireland',
  'Israel',
  'Isle of Man',
  'India',
  'British Indian Ocean Territory',
  'Iraq',
  'Iran, Islamic Republic of',
  'Iceland',
  'Italy',
  'Jersey',
  'Jamaica',
  'Jordan',
  'Japan',
  'Kenya',
  'Kyrgyzstan',
  'Cambodia',
  'Kiribati',
  'Comoros',
  'Saint Kitts and Nevis',
  "Korea, Democratic People's Republic of",
  'Korea, Republic of',
  'Kuwait',
  'Cayman Islands',
  'Kazakhstan',
  "Lao People's Democratic Republic",
  'Lebanon',
  'Saint Lucia',
  'Liechtenstein',
  'Sri Lanka',
  'Liberia',
  'Lesotho',
  'Lithuania',
  'Luxembourg',
  'Latvia',
  'Libya',
  'Morocco',
  'Monaco',
  'Moldova, Republic of',
  'Montenegro',
  'Saint Martin (French part)',
  'Madagascar',
  'Marshall Islands',
  'Macedonia, the Former Yugoslav Republic of',
  'Mali',
  'Myanmar',
  'Mongolia',
  'Macao',
  'Northern Mariana Islands',
  'Martinique',
  'Mauritania',
  'Montserrat',
  'Malta',
  'Mauritius',
  'Maldives',
  'Malawi',
  'Mexico',
  'Malaysia',
  'Mozambique',
  'Namibia',
  'New Caledonia',
  'Niger',
  'Norfolk Island',
  'Nigeria',
  'Nicaragua',
  'Netherlands',
  'Norway',
  'Nepal',
  'Nauru',
  'Niue',
  'New Zealand',
  'Oman',
  'Panama',
  'Peru',
  'French Polynesia',
  'Papua New Guinea',
  'Philippines',
  'Pakistan',
  'Poland',
  'Saint Pierre and Miquelon',
  'Pitcairn',
  'Puerto Rico',
  'Palestine, State of',
  'Portugal',
  'Palau',
  'Paraguay',
  'Qatar',
  'Reunion',
  'Romania',
  'Serbia',
  'Russian Federation',
  'Rwanda',
  'Saudi Arabia',
  'Solomon Islands',
  'Seychelles',
  'Sudan',
  'Sweden',
  'Singapore',
  'Saint Helena',
  'Slovenia',
  'Svalbard and Jan Mayen',
  'Slovakia',
  'Sierra Leone',
  'San Marino',
  'Senegal',
  'Somalia',
  'Suriname',
  'South Sudan',
  'Sao Tome and Principe',
  'El Salvador',
  'Sint Maarten (Dutch part)',
  'Syrian Arab Republic',
  'Swaziland',
  'Turks and Caicos Islands',
  'Chad',
  'French Southern Territories',
  'Togo',
  'Thailand',
  'Tajikistan',
  'Tokelau',
  'Timor-Leste',
  'Turkmenistan',
  'Tunisia',
  'Tonga',
  'Turkey',
  'Trinidad and Tobago',
  'Tuvalu',
  'Taiwan, Province of China',
  'United Republic of Tanzania',
  'Ukraine',
  'Uganda',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Holy See (Vatican City State)',
  'Saint Vincent and the Grenadines',
  'Venezuela',
  'British Virgin Islands',
  'US Virgin Islands',
  'Vietnam',
  'Vanuatu',
  'Wallis and Futuna',
  'Samoa',
  'Kosovo',
  'Yemen',
  'Mayotte',
  'South Africa',
  'Zambia',
  'Zimbabwe'
]

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%'
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  actionsContainer: {
    marginBottom: theme.spacing(2)
  },
  resetContainer: {
    padding: theme.spacing(3)
  },
  divider: {
    margin: '0 1rem'
  },
  companyImage: {
    width: '100px',
    height: '100px',
    margin: 'auto',
    marginBottom: '1rem'
  },
  exhibitorImageContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column'
  },
  filesContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'row',
    flexWrap: 'wrap'
  },
  fileContainer: {
    width: 'auto',
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column',
    padding: '0.5rem'
  },
  fileIcon: {
    margin: 'auto',
    marginBottom: '1rem'
  },
  loaderContainer: {
    display: 'flex',
    alignItems: 'center',
    flexFlow: 'column',
    width: '100%',
    height: '100%',
    position: 'absolute',
    background: 'rgba(255,255,255,0.7)',
    zIndex: 1000,
    left: 0,
    top: 0,
    right: 0,
    bottom: 0
  },
  loader: {
    margin: 'auto'
  }
}))

const findIndex = (arr, val) => R.findIndex(R.propEq('_id', val))(arr)

function getSteps() {
  return [
    // 'Account Type',
    'Booth Creation',
    'Booth Content',
    'Company Information',
    'Other Questions'
    // 'Additional Interactivity'
  ]
}

const getFormInitialState = exhibitor => {
  if (exhibitor === 'new' || !exhibitor) {
    return INITIAL_EXHIBITOR_STATE
  }

  return {
    ...exhibitor,
    company_video_url: exhibitor.company_videos
  }
}

export default ({
  client,
  session,
  onSignOut,
  setUserExhibitor,
  exhibitor,
  exhibitors,
  fetchExhibitors
}) => {
  const classes = useStyles()
  const [isLoading, setIsLoadingState] = React.useState(false)
  const [cats, setCats] = React.useState([])
  const [challengeType, setChallengeType] = React.useState({})
  const [availableSubCats, setAvailableSubcats] = React.useState(false)
  const [formData, setFormData] = React.useState(getFormInitialState(exhibitor))
  const [activeStep, setActiveStep] = React.useState(0)
  const [selectedChallengeType, setSelectedChallengeType] = React.useState('')
  const [videoHelp, toggleVideoHelp] = React.useState(false)
  const [zoomHelp, toggleZoomHelp] = React.useState(false)
  const steps = getSteps()

  React.useEffect(() => {
    getClient()
    const el =
      typeof document !== 'undefined' && document.getElementById('stepper-form')
    typeof document !== 'undefined' &&
      window.scroll({ top: el.offsetTop - 150, behavior: 'smooth' })
  }, [])

  React.useEffect(() => {
    const shouldDefineCategoriesFromVendor =
      formData._id != null &&
      formData.challengeType != null &&
      challengeType.length > 0
    if (shouldDefineCategoriesFromVendor) {
      const catIndex = findIndex(challengeType, formData.challengeType)
      if (catIndex in challengeType && challengeType[catIndex].categories) {
        setSelectedChallengeType(challengeType[catIndex].title)
        setCats(challengeType[catIndex].categories)
      }
    }
  }, [formData._id, challengeType, formData._rev])

  React.useEffect(() => {
    const isDifferentRevision =
      '_rev' in formData &&
      exhibitor != null &&
      exhibitor._rev !== formData._rev
    const isNewExhibitor =
      !('_id' in formData) && exhibitor != null && exhibitor._id != null
    if (isDifferentRevision || isNewExhibitor) {
      setFormData(getFormInitialState(exhibitor))
    }
  }, [exhibitor])

  function getClient() {
    const query = `*[_type == "vendorType"] {
        _id,
        title,
        slug,
        categories[]->{...}
      }
    `
    client
      .fetch(query)
      .then(vt => {
        setChallengeType(vt)
      })
      .catch(err => console.error(err))
  }

  function _handleCountryChange(value) {
    setFormData({
      ...formData,
      country: value
    })
  }

  function _handleInputChange(e) {
    e.preventDefault()
    if (
      R.or(
        R.equals(e.target.name, 'solution_id'),
        R.equals(e.target.name, 'company_address_zipcode')
      ) &&
      e.target.value.length > 5
    ) {
      return
    }
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })
  }

  function _handleAddingImage(id, e) {
    const name = `${id}_name`
    notNilOrEmpty(e) &&
      setFormData({
        ...formData,
        [id]: e[0],
        [name]: e[0].name
      })
  }

  function _handleAddingFiles(e) {
    notNilOrEmpty(e) &&
      setFormData({
        ...formData,
        company_file_asset: e
      })
  }

  function _handleChallengeType(e) {
    e.preventDefault()

    setAvailableSubcats(false)

    const catIndex = findIndex(challengeType, e.target.value)

    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    })

    if ([catIndex].categories) {
      setSelectedChallengeType([catIndex].title)
      setAvailableSubcats(true)
    } else {
      setAvailableSubcats(false)
    }

    setCats(challengeType[catIndex].categories)
  }

  async function _handleForm(e) {
    try {
      e.preventDefault()
      setIsLoadingState(true)
      // creating the object to send to sanity
      const nextExhibitor = new Exhibitor(
        formData,
        client,
        session.user._id,
        getFormInitialState(exhibitor)
      )
      const exhibitorRecord = await (nextExhibitor.isNew()
        ? nextExhibitor.createExhibitor()
        : nextExhibitor.updateExhibitor())

      Swal.fire({
        title: 'Thank you for your booth submission.',
        text:
          'We will review it and publish it to the site as soon as possible.',
        icon: 'success',
        showConfirmButton: false,
        confirmButtonText: 'Send',
        showCloseButton: true,
        heightAuto: false,
        padding: '3em',
        customClass: {
          popup: 'popup',
          confirmButton: 'btn btn--black btn-confirm'
        }
      })
      setUserExhibitor(exhibitorRecord)
      setActiveStep(0)
      setIsLoadingState(false)
      fetchExhibitors()
    } catch (error) {
      console.error('ERROR', error)
      setIsLoadingState(false)
      Swal.fire({
        title: 'Failed to save your information.',
        text: 'Please try again or contact support!',
        icon: 'error',
        showConfirmButton: false,
        confirmButtonText: '',
        showCloseButton: true,
        heightAuto: false,
        padding: '3em',
        customClass: {
          popup: 'popup',
          confirmButton: 'btn btn--black btn-confirm'
        }
      })
    }
  }

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
    const el =
      typeof document !== 'undefined' && document.getElementById('stepper-form')
    typeof document !== 'undefined' &&
      window.scroll({ top: el.offsetTop - 150, behavior: 'smooth' })
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
    const el =
      typeof document !== 'undefined' && document.getElementById('stepper-form')
    typeof document !== 'undefined' &&
      window.scroll({ top: el.offsetTop - 150, behavior: 'smooth' })
  }

  const _handleStepperBUtton = n => {
    setActiveStep(n)
  }

  const removeExhibitorResource = (unset, resourceType) => {
    setIsLoadingState(true)
    client
      .patch(formData._id)
      .unset(unset)
      .commit()
      .then(async res => {
        const exhibitorRecord = new Exhibitor({}, client, null, {})
        const updatedExhibitor = await exhibitorRecord.getLatestDocument(
          formData._id
        )
        setUserExhibitor(updatedExhibitor)
        setIsLoadingState(false)
        Swal.fire({
          title: 'Record Deleted',
          text: `The requested ${resourceType} was deleted successfuly.`,
          icon: 'success',
          showConfirmButton: false,
          confirmButtonText: 'Send',
          showCloseButton: true,
          heightAuto: false,
          padding: '3em',
          customClass: {
            popup: 'popup',
            confirmButton: 'btn btn--black btn-confirm'
          }
        })
      })
      .catch(err => {
        console.error('Delete failed: ', err.message)
        setIsLoadingState(false)
        Swal.fire({
          title: `Failed to delete the requested ${resourceType}.`,
          text: 'Please try again or contact support!',
          icon: 'error',
          showConfirmButton: false,
          confirmButtonText: '',
          showCloseButton: true,
          heightAuto: false,
          padding: '3em',
          customClass: {
            popup: 'popup',
            confirmButton: 'btn btn--black btn-confirm'
          }
        })
      })
  }

  function _createNewExhibitor() {
    setUserExhibitor('new')
    setActiveStep(0)
    setFormData(getFormInitialState('new'))
  }

  return (
    <div className="container exhibitor-page page--new-exhibitor">
      {isLoading && (
        <div className={classes.loaderContainer}>
          <CircularProgress className={classes.loader} />
        </div>
      )}
      <div
        className={`animated fadeIn section-content form-container-alignment`}
      >
        {notNilOrEmpty(session) && notNilOrEmpty(session.user) ? (
          <Box
            display="flex"
            p={1}
            flexDirection="row"
            alignItems="center"
            justifyItems="space-between"
            className="form-top-section"
          >
            <div className="form-top-section--left">
              <Typography variant="caption">
                Signed In As: {session.user.email}
              </Typography>
              <Divider
                orientation="vertical"
                flexItem
                className={classes.divider}
              />
              <Typography variant="caption">
                <Link href="#" onClick={onSignOut}>
                  Sign Out
                </Link>
              </Typography>
            </div>
            <div className="form-top-section--right">
              <Button
                onClick={_createNewExhibitor}
                className="btn--yellow"
                variant="contained"
                color="primary"
              >
                Add New
              </Button>
              {notNilOrEmpty(session.user) && notNilOrEmpty(exhibitors) && (
                <ExhibitorsDropdown
                  exhibitors={exhibitors}
                  selectedExhibitorId={exhibitor._id}
                  setSelectedExhibitor={setUserExhibitor}
                />
              )}
            </div>
          </Box>
        ) : null}
        <div className="form-container">
          <form onSubmit={_handleForm}>
            <Stepper
              id="stepper-form"
              activeStep={activeStep}
              orientation="vertical"
            >
              <Step id="step-0">
                <StepButton onClick={() => _handleStepperBUtton(0)}>
                  {steps[0]}
                </StepButton>
                <StepContent>
                  <FormControl variant="outlined" className="form-input">
                    <TextField
                      className="search-box-label"
                      helperText={`Please briefly tell us about your solution (200 words limit) / ${200 -
                        R.length(
                          R.split(' ', formData.bc__who_are_you)
                        )} words left`}
                      label="Your Solution"
                      variant="outlined"
                      color="secondary"
                      name="bc__who_are_you"
                      onChange={_handleInputChange}
                      value={formData.bc__who_are_you}
                      multiline
                      rows={8}
                      required
                      error={R.gte(
                        R.length(R.split(' ', formData.bc__who_are_you)),
                        200
                      )}
                    />
                  </FormControl>
                  <FormControl variant="outlined" className="form-input">
                    <TextField
                      className="search-box-label"
                      label="Promotional Video"
                      variant="outlined"
                      color="secondary"
                      name="company_video_url"
                      onChange={_handleInputChange}
                      value={formData.company_video_url}
                      error={nilOrEmpty(formData.company_video_url)}
                      helperText="Youtube Link (eg. https://youtu.be/wH3xZen_nBY)"
                    />
                    <div>
                      <small>
                        How to get the Youtube URL{' '}
                        <a
                          onClick={() => toggleVideoHelp(!videoHelp)}
                          className="link--black"
                        >
                          <u>(click here)</u>
                        </a>
                      </small>
                    </div>
                  </FormControl>
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button onClick={handleBack} className={classes.button}>
                        Previous Step
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                        disabled={R.not(
                          R.and(
                            notNilOrEmpty(formData.bc__who_are_you),
                            R.lte(
                              R.length(R.split(' ', formData.bc__who_are_you)),
                              200
                            )
                          )
                        )}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>
              <Step id="step-1">
                <StepButton onClick={() => _handleStepperBUtton(1)}>
                  {steps[1]}
                </StepButton>
                <StepContent>
                  <InputLabel required className="form-input" id="logo-label">
                    Team Logo
                  </InputLabel>
                  {formData._id != null && formData.company_img_url != null && (
                    <div className={classes.exhibitorImageContainer}>
                      <img
                        src={formData.company_img_url}
                        alt="Company Image"
                        className={classes.companyImage}
                      />
                      <Button
                        color="secondary"
                        onClick={() => removeExhibitorResource(['company_img'])}
                      >
                        Remove
                      </Button>
                    </div>
                  )}
                  <FormControl variant="outlined" className="form-input">
                    <DropzoneArea
                      required
                      acceptedFiles={['image/*']}
                      dropzoneText={
                        'Drag and drop your team logo here or click. Jpg or Png files accepted.'
                      }
                      filesLimit={1}
                      id="image-path"
                      name="company_img"
                      onChange={e => _handleAddingImage('company_img', e)}
                      initialFiles={[formData.company_img]}
                    />
                  </FormControl>
                  <InputLabel className="form-input" id="logo-label">
                    Team Brochure(s)
                  </InputLabel>
                  {formData._id != null && (
                    <div className={classes.filesContainer}>
                      {notNilOrEmpty(formData.company_file_asset) &&
                        mapIndexed((asset, index) => {
                          return asset._key != null ? (
                            <div className={classes.fileContainer}>
                              <FontAwesomeIcon
                                icon={faFile}
                                mask={['circle']}
                                size="2x"
                                className={classes.fileIcon}
                              />
                              <small>
                                {formData.company_files.name[index]}
                              </small>
                              <Button
                                color="secondary"
                                onClick={() =>
                                  removeExhibitorResource([
                                    `company_file_asset[_key=="${asset._key}"]`
                                  ])
                                }
                              >
                                Remove
                              </Button>
                            </div>
                          ) : null
                        })(formData.company_file_asset)}
                    </div>
                  )}
                  <FormControl variant="outlined" className="form-input">
                    <DropzoneArea
                      acceptedFiles={['application/pdf']}
                      dropzoneText={
                        'Upload relevant materials: 10mgb per file, 3 files max'
                      }
                      id="file-path"
                      getPreviewIcon={() => <PictureAsPdf />}
                      showFileNames
                      previewGridProps={{
                        container: { spacing: 1, direction: 'row' }
                      }}
                      previewText="Selected files"
                      maxFileSize={10000000}
                      name="company_file_asset"
                      onChange={_handleAddingFiles}
                      value={formData.company_file_asset}
                      previewGridClasses={{ container: 'files-dropzone' }}
                      initialFiles={formData.company_file_asset}
                    />
                  </FormControl>
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button onClick={handleBack} className={classes.button}>
                        Previous Step
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleNext}
                        className={classes.button}
                        disabled={R.not(notNilOrEmpty(formData.company_img))}
                      >
                        Next
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>
              <Step id="step-2">
                <StepButton onClick={() => _handleStepperBUtton(2)}>
                  {steps[2]}
                </StepButton>
                <StepContent>
                  <FormControl variant="outlined" className="form-input">
                    <TextField
                      className="search-box-label"
                      label="Company / Team Name"
                      variant="outlined"
                      color="secondary"
                      name="title"
                      onChange={_handleInputChange}
                      value={formData.title}
                      required
                      error={
                        R.equals(exhibitor, 'new')
                          ? R.gt(R.length(formData.title), 79)
                          : null
                      }
                      helperText={
                        R.equals(exhibitor, 'new')
                          ? `(79 characters limit) / ${79 -
                              R.length(formData.title)} chars left}`
                          : ''
                      }
                    />
                  </FormControl>
                  <FormControl variant="outlined" className="form-input">
                    <TextField
                      className="search-box-label"
                      color="secondary"
                      helperText="Please list the headquarters of your company"
                      label="Company HQ Address"
                      name="company_address"
                      onChange={_handleInputChange}
                      required
                      value={formData.company_address}
                      variant="outlined"
                    />
                  </FormControl>
                  <FormControl variant="outlined" className="form-input">
                    <TextField
                      className="search-box-label"
                      color="secondary"
                      helperText="Please list the headquarters of your company"
                      label="Company HQ City"
                      name="company_address_city"
                      onChange={_handleInputChange}
                      required
                      value={formData.company_address_city}
                      variant="outlined"
                    />
                  </FormControl>
                  {R.equals(formData.country, 'United States') && (
                    <>
                      <FormControl variant="outlined" className="form-input">
                        <TextField
                          className="search-box-label"
                          color="secondary"
                          helperText="HQ State"
                          label="Company HQ State"
                          name="company_address_state"
                          onChange={_handleInputChange}
                          required
                          value={formData.company_address_state}
                          variant="outlined"
                        />
                      </FormControl>
                      <FormControl variant="outlined" className="form-input">
                        <TextField
                          className="search-box-label"
                          color="secondary"
                          error={
                            formData.company_address_zipcode.length > 0
                              ? R.lt(
                                  R.length(formData.company_address_zipcode),
                                  5
                                )
                              : false
                          }
                          helperText="5 digit zipcode"
                          inputProps={{
                            maxLength: 5,
                            minLength: 5
                          }}
                          label="Company HQ Zipcode"
                          name="company_address_zipcode"
                          onChange={_handleInputChange}
                          required
                          value={formData.company_address_zipcode}
                          variant="outlined"
                          type="number"
                        />
                      </FormControl>
                    </>
                  )}
                  <FormControl variant="outlined" className="form-input">
                    <TextField
                      className="search-box-label"
                      color="secondary"
                      error={
                        notNilOrEmpty(formData.company_url) &&
                        !R.startsWith('http', formData.company_url)
                      }
                      helperText="Please include http:// or https://"
                      label="Company Website"
                      name="company_url"
                      onChange={_handleInputChange}
                      value={formData.company_url}
                      variant="outlined"
                    />
                  </FormControl>
                  <FormControl variant="outlined" className="form-input">
                    <TextField
                      className="search-box-label"
                      label="Booth Primary Contact"
                      variant="outlined"
                      color="secondary"
                      name="company_primary_contact"
                      onChange={_handleInputChange}
                      value={formData.company_primary_contact}
                      required
                    />
                  </FormControl>
                  <FormControl variant="outlined" className="form-input">
                    <TextField
                      className="search-box-label"
                      label="Booth Primary Contact Email"
                      variant="outlined"
                      color="secondary"
                      type="email"
                      name="company_primary_contact_email"
                      error={
                        notNilOrEmpty(formData.company_primary_contact_email) &&
                        R.not(
                          notNilOrEmpty(
                            R.match(
                              /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                              formData.company_primary_contact_email
                            )
                          )
                        )
                      }
                      onChange={_handleInputChange}
                      value={formData.company_primary_contact_email}
                      required
                    />
                  </FormControl>
                  <FormControl variant="outlined" className="form-input">
                    <TextField
                      className="search-box-label"
                      label="Phone Number"
                      variant="outlined"
                      color="secondary"
                      name="company_phone"
                      onChange={_handleInputChange}
                      value={formData.company_phone}
                    />
                  </FormControl>
                  <div className={classes.actionsContainer}>
                    <div>
                      <Button onClick={handleBack} className={classes.button}>
                        Previous Step
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={_handleForm}
                        className={classes.button}
                        disabled={R.not(
                          R.and(
                            R.and(
                              R.and(
                                notNilOrEmpty(formData.title),
                                R.cond([
                                  [
                                    R.equals('new'),
                                    R.always(
                                      R.lte(R.length(formData.title), 79)
                                    )
                                  ],
                                  [R.is(Object), R.always(true)],
                                  [R.T, R.T]
                                ])(exhibitor)
                              ),
                              notNilOrEmpty(formData.company_address)
                            ),
                            R.and(
                              notNilOrEmpty(formData.company_primary_contact),
                              notNilOrEmpty(
                                formData.company_primary_contact_email
                              )
                            )
                          )
                        )}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </StepContent>
              </Step>
              {
                // <Step id="step-4">
                //   <StepButton onClick={() => _handleStepperBUtton(4)}>
                //     {steps[4]}
                //   </StepButton>
                //   <StepContent>
                //     <div>
                //       <small>
                //         <strong>Disclaimer:</strong> The following questions will
                //         not influence the AFWERX Challenge selection process.
                //         Their intention is to match you with other potential
                //         government opportunities in the future. The responses to
                //         these questions will not be taken into consideration
                //         during the pitches or the evaluation phase.
                //       </small>
                //     </div>
                //     <FormControl variant="outlined" className="form-input">
                //       <TextField
                //         className="search-box-label"
                //         color="secondary"
                //         label="Do you claim SBA Small Business Status?"
                //         helperText="fewer than 500 employees for manufacturing businesses and less than $7.5 million in annual receipts for most non-manufacturing businesses"
                //         name="sba_status"
                //         onChange={_handleInputChange}
                //         select
                //         value={formData.sba_status}
                //         variant="outlined"
                //       >
                //         <MenuItem value="Yes">
                //           <ListItemText primary="Yes" />
                //         </MenuItem>
                //         <MenuItem value="No">
                //           <ListItemText primary="No" />
                //         </MenuItem>
                //       </TextField>
                //     </FormControl>
                //     <FormControl variant="outlined" className="form-input">
                //       <TextField
                //         className="search-box-label"
                //         color="secondary"
                //         label="Other Business Statuses"
                //         name="other_business_status"
                //         onChange={_handleInputChange}
                //         select
                //         value={formData.other_business_status}
                //         variant="outlined"
                //       >
                //         <MenuItem value="fifty-one-us">
                //           <ListItemText primary="51 percent owned and controlled by U.S. citizens" />
                //         </MenuItem>
                //         <MenuItem value="non-us">
                //           <ListItemText primary="Non-U.S. Owned" />
                //         </MenuItem>
                //       </TextField>
                //     </FormControl>
                //     <FormControl variant="outlined" className="form-input">
                //       <TextField
                //         className="search-box-label"
                //         color="secondary"
                //         label="Are you a Women's Business Enterprise?"
                //         name="wbe"
                //         onChange={_handleInputChange}
                //         select
                //         value={formData.wbe}
                //         variant="outlined"
                //       >
                //         <MenuItem value="Yes">
                //           <ListItemText primary="Yes" />
                //         </MenuItem>
                //         <MenuItem value="No">
                //           <ListItemText primary="No" />
                //         </MenuItem>
                //       </TextField>
                //     </FormControl>
                //     <FormControl variant="outlined" className="form-input">
                //       <TextField
                //         className="search-box-label"
                //         color="secondary"
                //         label="Minority owned?"
                //         name="minority_owned"
                //         onChange={_handleInputChange}
                //         select
                //         value={formData.minority_owned}
                //         variant="outlined"
                //       >
                //         <MenuItem value="Yes">
                //           <ListItemText primary="Yes" />
                //         </MenuItem>
                //         <MenuItem value="No">
                //           <ListItemText primary="No" />
                //         </MenuItem>
                //       </TextField>
                //     </FormControl>
                //     <FormControl variant="outlined" className="form-input">
                //       <TextField
                //         className="search-box-label"
                //         color="secondary"
                //         label="Veteran owned?"
                //         name="veteran_owned"
                //         onChange={_handleInputChange}
                //         select
                //         value={formData.veteran_owned}
                //         variant="outlined"
                //       >
                //         <MenuItem value="Yes">
                //           <ListItemText primary="Yes" />
                //         </MenuItem>
                //         <MenuItem value="No">
                //           <ListItemText primary="No" />
                //         </MenuItem>
                //       </TextField>
                //     </FormControl>
                //     <div className={classes.actionsContainer}>
                //       <div>
                //         <Button onClick={handleBack} className={classes.button}>
                //           Previous Step
                //         </Button>
                //         <Button
                //           variant="contained"
                //           color="primary"
                //           onClick={_handleForm}
                //           className={classes.button}
                //         >
                //           Submit
                //         </Button>
                //       </div>
                //     </div>
                //   </StepContent>
                // </Step>
              }
              {
                // <Step>
                //   <StepButton onClick={() => _handleStepperBUtton(5)}>
                //     {steps[5]}
                //   </StepButton>
                //   <StepContent>
                //     <FormControl variant="outlined" className="form-input">
                //       <TextField
                //         className="search-box-label"
                //         label="Chat Link"
                //         variant="outlined"
                //         color="secondary"
                //         name="tawkto_chat_link"
                //         onChange={_handleInputChange}
                //         value={formData.tawkto_chat_link}
                //         error={
                //           nilOrEmpty(formData.tawkto_chat_link) &&
                //           !R.startsWith('http', formData.tawkto_chat_link)
                //         }
                //         helperText="Your Direct Chat Link from Tawk.to"
                //       />
                //     </FormControl>
                //     <FormControl variant="outlined" className="form-input">
                //       <TextField
                //         className="search-box-label"
                //         label="Recurring Zoom Meeting URL"
                //         variant="outlined"
                //         color="secondary"
                //         name="company_zoom_url"
                //         error={
                //           notNilOrEmpty(formData.company_zoom_url) &&
                //           !R.startsWith('http', formData.company_zoom_url)
                //         }
                //         onChange={_handleInputChange}
                //         value={formData.company_zoom_url}
                //         helperText="Please include http:// or https://"
                //       />
                //       <div>
                //         <small>
                //           How to get the Zoom Link{' '}
                //           <a
                //             onClick={() => toggleZoomHelp(!zoomHelp)}
                //             className="link--black"
                //           >
                //             <u>(click here)</u>
                //           </a>
                //         </small>
                //       </div>
                //     </FormControl>
                //     <div className={classes.actionsContainer}>
                //       <div>
                //         <Button onClick={handleBack} className={classes.button}>
                //           Previous Step
                //         </Button>
                //         <Button
                //           variant="contained"
                //           color="primary"
                //           onClick={_handleForm}
                //           className={classes.button}
                //         >
                //           Submit
                //         </Button>
                //       </div>
                //     </div>
                //   </StepContent>
                // </Step>
              }
            </Stepper>
            <FsLightbox
              toggler={videoHelp}
              sources={[`${require('../assets/imgs/youtube-share-link.jpg')}`]}
            />
            <FsLightbox
              toggler={zoomHelp}
              sources={[
                <iframe
                  allow="fullscreen"
                  allowFullScreen
                  className="lightbox lightbox__iframe__files"
                  frameBorder="0"
                  height="1080"
                  src={`${require('../assets/pdfs/Zoom-Instructions-for-Exhibitors.pdf')}`}
                  title="Zoom Help"
                  width="1920"
                />
              ]}
            />
          </form>
        </div>
      </div>
    </div>
  )
}
